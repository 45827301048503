export const text = {
    app_alert: {
        notice: {
            vi: 'Thông báo',
            en: 'Notice'
        }
    },
    auth: {
        login: {
            vi: 'Đăng nhập tài khoản LocaMos',
            en: 'Login with LocaMos Account',

            username: {
                vi: 'Số điện thoại',
                en: 'Phone number',
            },
            password: {
                vi: 'Mật khẩu truy cập',
                en: 'Password',
            },
            forgot_password: {
                vi: 'Quên mật khẩu?',
                en: 'Forgot password?',
            },
            login: {
                vi: 'Đăng nhập',
                en: 'Login',
            },
            register: {
                vi: 'Chưa có tài khoản? Đăng ký',
                en: 'Register an account',
            },
            terms_of_services: {
                vi: 'Điều khoản sử dụng',
                en: 'Terms of Services',
            },
            field_can_not_be_blank: {
                vi: 'Tên đăng nhập hoặc mật khẩu không được để trống!',
                en: 'Username or password can not be blank!'
            },
        },
        register: {
            vi: 'Đăng ký tài khoản',
            en: 'Register an account',
            
            username: {
                vi: 'SĐT/Email đăng nhập',
                en: 'Phone/Email',
                de: 'Phone/Email',
            },
            full_name: {
                vi: 'Họ tên đầy đủ',
                en: 'Full Name',
                de: 'Full Name',
            },
            email: {
                vi: 'Email',
                en: 'Email',
                de: 'Email',
            },
            phone_number: {
                vi: 'Số điện thoại',
                en: 'Phone Number',
                de: 'Phone Number',
            },
            password: {
                vi: 'Mật khẩu đăng nhập',
                en: 'Password',
                de: 'Password',
            },
            province: {
                vi: 'Tỉnh/Thành phố',
                en: 'City/Province',
                de: 'City/Province'
            },
            district: {
                vi: 'Quận/Huyện',
                en: 'District',
                de: 'Kreis'
            },
            please_select: {
                vi: 'Vui lòng chọn',
                en: 'Please select',
                de: 'Bitte auswählen'
            },
            address: {
                vi: 'Địa chỉ',
                en: 'Address',
                de: 'Address',
            },
            register: {
                vi: 'Đăng ký',
                en: 'Register',
                de: 'Registrieren',
            },
            code_ref: {
                vi: 'Mã giới thiệu',
                en: 'Referral Code',
                de: 'Referenzcode',
            }
        },
        forgot_password: {
            vi: 'Quên mật khẩu',
            en: 'Forgot password',

            enter_registered_phone_number: {
                vi: 'Nhập số điện thoại đã đăng ký',
                en: 'Enter your registered phone number',
            },
            send_request: {
                vi: 'Gửi yêu cầu',
                en: 'Send request'
            },
            field_can_not_be_blank: {
                vi: 'Tên đăng nhập không được để trống!',
                en: 'Username can not be blank!'
            }
        },
        return_to_login: {
            vi: 'Quay lại trang đăng nhập',
            en: 'Return to login page'
        }
    },
    top_nav_bar: {
        home: {
            vi: 'Trang chủ',
            en: 'Home'
        },
        contact: {
            vi: 'Liên hệ',
            en: 'Contact'
        },
        about_us: {
            vi: 'Về chúng tôi',
            en: 'About Us'
        },
        features: {
            vi: 'Tính năng',
            en: 'Features',

            all_features: {
                vi: 'Tất cả Tính năng',
                en: 'All Features'
            },
            for_users: {
                vi: 'Cho Người Dùng',
                en: 'Features for Users'
            },
            for_businesses: {
                vi: 'Cho Doanh Nghiệp',
                en: 'For LocaNFC Businesses'
            },
            for_owners: {
                vi: 'Cho Chủ LocaNFT',
                en: 'For LocaNFT Owners'
            }
        },
        explore: {
            vi: 'Khám phá',
            en: 'Explore'
        },
        ecosystem: {
            vi: 'Hệ sinh thái',
            en: 'Ecosystem'
        },
        tokenomics: {
            vi: 'Tokenomics',
            en: 'Tokenomics'
        },
        services: {
            vi: 'Dịch vụ',
            en: 'Services',

            for_users: {
                vi: 'Cho Người dùng',
                en: 'For Users'
            },
            for_businesses: {
                vi: 'Cho Kinh doanh',
                en: 'For Businesses'
            },
            for_partners: {
                vi: 'Cho Đối tác',
                en: 'For Partners'
            },
        },
        use_cases: {
            vi: 'Đối tượng',
            en: 'Use Cases'
        },
        resources: {
            vi: 'Thông tin',
            en: 'Resources'
        },
        contact_us: {
            vi: 'Liên hệ',
            en: 'Contact Us'
        },
        support: {
            vi: 'Hỗ trợ',
            en: 'Support'
        },
        account: {
            vi: 'Tài khoản',
            en: 'Account'
        },
        logout: {
            vi: 'Đăng xuất',
            en: 'Log out'
        },
        login: {
            vi: 'Đăng nhập',
            en: 'Log in'
        },
        market_developer: {
            vi: 'Phát triển Thị trường',
            en: 'Market Developer'
        }
    },
    footer: {
        home: {
            vi: 'Trang chủ',
            en: 'Home',

            about_us: {
                vi: 'Về chúng tôi',
                en: 'About Us'
            },
            services: {
                vi: 'Dịch vụ',
                en: 'Services'
            },
            use_cases: {
                vi: 'Đối tượng',
                en: 'Use Cases'
            }
        },
        quick_links: {
            vi: 'Đường dẫn',
            en: 'Quick links',

            white_paper: {
                vi: 'Sách trắng',
                en: 'Whitepaper'
            },
            audit: {
                vi: 'Audit',
                en: 'Audit'
            },
            faqs: {
                vi: 'FAQs',
                en: 'FAQs'
            }
        },
        resources: {
            vi: 'Thông tin',
            en: 'Resources',

            white_paper: {
                vi: 'Sách trắng',
                en: 'Whitepaper'
            },
            audit: {
                vi: 'Audit',
                en: 'Audit'
            },
            faqs: {
                vi: 'FAQs',
                en: 'FAQs'
            }
        },
        follow_us: {
            vi: 'Theo dõi',
            en: 'Follow us'
        },
        links: {
            tos: {
                vi: 'Điều khoản dịch vụ',
                en: 'Terms of Service'
            },
            privacy_policy: {
                vi: 'Chính sách bảo mật',
                en: 'Privacy Policy'
            },
            payment_policy: {
                vi: 'Chính sách thanh toán',
                en: 'Payment Policy'
            },
            website_owner: {
                vi: 'Thông tin chủ sở hữu',
                en: 'Website Owner'
            }
        },
    },
}