import { API, Request } from 'network';

const register_membership = (data) => {
    const request = new Request();
    
    let post_data = JSON.stringify(data);

    return request.post(API.REGISTER_MEMBERSHIP, post_data, {
        headers: { 
            'Content-Type': 'application/json'
        }
    }).then(result => {
        try {
            if (result.status === 200) {
                return true;
            } else {
                console.log('API ' + API.REGISTER_MEMBERSHIP + ' lỗi status: ' + result.status + ', nội dung: ' + result.content)
                return result.content;
            }
        } catch (error) {
            console.log('API ' + API.REGISTER_MEMBERSHIP + ' lỗi trycatch: ' + error);
            return null;
        }
    })
}

const register_partner = (email) => {
    const request = new Request();

    let post_data = JSON.stringify({ email });

    return request.post(API.REGISTER_PARTNER, post_data, {
        headers: { 
            'Content-Type': 'application/json',
        },
        withCredentials: false
    }).then(result => {
        try {
            if (result.status === 200) {
                return true;
            } else {
                console.log('API ' + API.REGISTER_PARTNER + ' lỗi status: ' + result.status + ', nội dung: ' + result.content)
                return null;
            }
        } catch (error) {
            console.log('API ' + API.REGISTER_PARTNER + ' lỗi trycatch: ' + error);
            return null;
        }
    });
}

export const homepageService = {
    register_membership,
    register_partner
}