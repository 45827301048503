import { NAVIGATION } from './routes';

const BASE_API = 'https://ldp.locago.tech'
// const BASE_API = 'http://localhost:4000'
const BASE_API_LOCAMOS = ''
const BASE_IMAGE = ''

const HOMEPAGE = ''

const GOOGLE_CLIENT_ID = ''
const APPLE_CLIENT_ID = ''
const APPLE_REDIRECT_URI = ''

const SHIPPING_FEES_3KM = 20000

export {
    BASE_API,
    BASE_API_LOCAMOS,
    HOMEPAGE,
    NAVIGATION,
    BASE_IMAGE,
    GOOGLE_CLIENT_ID,
    APPLE_CLIENT_ID, APPLE_REDIRECT_URI,
    SHIPPING_FEES_3KM
}